import React from 'react';

const GoogleMap = () => {
  return (
    <div className="row">
      {/* Contenedor responsivo */}
      <div className="embed-responsive embed-responsive-16by9">
        {/* Mapa de Google */}
        <iframe 
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3733.6217773839544!2d-103.40049499999999!3d20.6442679!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428adcc0b0779f5%3A0xced937eef2adc132!2sAv.%20Obsidiana%202760%2C%20Victoria%2C%2044089%20Zapopan%2C%20Jal.!5e0!3m2!1ses-419!2smx!4v1718923750440!5m2!1ses-419!2smx" 
          width="auto" 
          height="400" 
          className="embed-responsive-item"
          style={{ border: '0' }} 
          allowFullScreen="" 
          loading="lazy" 
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default GoogleMap;

