import React, { Component } from 'react';
import homedata from '../../data/homedata.json';
import axios from 'axios';
import Swal from "sweetalert2";
import WhatsAppIcon from './whatsapp'
import GoogleMap from './mapa'





class Contact extends Component {

   constructor(props) {
      super(props);
      this.state = {
         envioExitoso: false, // Estado para controlar la visibilidad del mensaje de éxito
      };
   }
   clearForm = () => {
      this.setState({
         formData: {
            name: '',
            email: '',
            subject: '',
            message: ''
         }
      });
   };

   handleSubmit = (e) => {
      e.preventDefault(); // Evita que el formulario se envíe de forma convencional

      // Obtener los valores del formulario usando FormData
      const formData = new FormData(e.target);
      const formDataObject = {};
      formData.forEach((value, key) => {
         formDataObject[key] = value;
      });

      // Mostrar los datos en la consola
      console.log('Datos del formulario:', formDataObject.email);



      e.preventDefault();
      // Obtener los valores de los campos del formulario
      const nombre = e.target.elements.name.value;
      const correo = e.target.elements.email.value;
      const mensaje = e.target.elements.message.value;
      //Cambiar estos datos para cada pagina y su respectivo contacto
      const nombreProp = 'MOOD LIFE';
      const correoProp = 'contacto@modlife.com.mx';
      const pagina = 'modlife.com.mx';

      // Crear un objeto con los datos a enviar
      const data = {
         nombre,
         correo,
         mensaje,
         nombreProp,
         correoProp,
         pagina
      };

      console.log(data)
      // Realizar la solicitud POST utilizando Axios
      axios.post('https://taeconta.com/api/public/api/correos/publicos', data)
         .then(response => {
            console.log('Correo enviado con éxito:', response.data);
            this.setState({ envioExitoso: true });
            Swal.fire(
               "Correo enviado exitosamente",
               "Hemos recibido tu mensaje y nos pondremos en contacto contigo lo antes posible. Gracias por ponerte en contacto con nosotros.",
               "success"
            );
            //   setCargando(false)
            this.clearForm()
         })
         .catch(error => {
            console.error('Error al enviar el correo:', error);
            Swal.fire(
               "Error al enviar el correo",
               error.message + ", " + error.response.data.message,
               "error"
            );
            //   setCargando(false)
            //   clearState(e)
         });


   };

   render() {
      return (
         <section id="contact" className="p-80px-tb">
            <div className="container">
               <div className="row">
                  <div className="col-sm-8 offset-sm-2">
                     <div className="section-title text-center m-50px-b">
                        <h2>{homedata.contact.title}</h2>
                     </div>
                  </div>
               </div>
               <div className="row row-eq-height">
                  <div className="col-lg-4 col-md-6 contact-address p-30px">
                     {/* dirección */}
                     <div className="address-box text-center p-15px m-15px-b">
                        <a style={{ color: 'gray' }} href={homedata.contact.contactinfo.address.link} target="_blank" rel="noopener noreferrer">
                           <i className={homedata.contact.contactinfo.address.icon}></i>
                           <h5>{homedata.contact.contactinfo.address.title}</h5>
                           <p dangerouslySetInnerHTML={{ __html: homedata.contact.contactinfo.address.info }}></p>
                        </a>
                     </div>
                     {/* teléfono */}
                     <div className="address-box text-center p-15px m-15px-b">
                        <a href={`https://wa.me/${homedata.contact.contactinfo.phone.info}`} target='_blank' style={{ color: 'gray', textDecoration: 'none' }}>
                           <i className={homedata.contact.contactinfo.phone.icon} style={{ color: 'gray' }}></i>
                           <h5>{homedata.contact.contactinfo.phone.title}</h5>
                           <p style={{ color: 'gray' }} dangerouslySetInnerHTML={{ __html: homedata.contact.contactinfo.phone.info }}></p>
                        </a>
                     </div>
                     {/* correo */}
                     <div className="address-box text-center p-15px">
                        <a href={`mailto:${homedata.contact.contactinfo.email.info}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                           <i className={homedata.contact.contactinfo.email.icon}></i>
                           <h5>{homedata.contact.contactinfo.email.title}</h5>
                           <p dangerouslySetInnerHTML={{ __html: homedata.contact.contactinfo.email.info }}></p>
                        </a>
                     </div>
                  </div>
                  <div className="col-lg-8 col-md-6 contact-form p-30px">
                     <h3 className="m-25px-b">{homedata.contact.formtitle}</h3>
                     <p className="m-25px-b">{homedata.contact.formdescription}</p>
                     <form id="contact-form" onSubmit={this.handleSubmit} method="post" action="#">
                        <div className="message">
                           {this.state.envioExitoso && (
                              <p className="email-success alert alert-success">
                                 <i className="icon icon-icon-check-alt2"></i> Tu mensaje se ha enviado correctamente.
                                 <button type="button" className="close" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                 </button>
                              </p>
                           )}
                           <p className="email-failed alert alert-danger"><i className="icon icon-icon-close-alt2"></i> ¡Algo salió mal!  <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                              <span aria-hidden="true">&times;</span></button>
                           </p>
                        </div>
                        <div className="mb13">
                           <input name="name" className="contact-name" id="contact-name" type="text" placeholder="Su nombre" required="" />
                        </div>
                        <div className="mb13">
                           <input name="email" className="contact-email" id="contact-email" type="email" placeholder="Su correo" required="" />
                        </div>
                        <div className="mb30">
                           <textarea name="message" className="contact-message" id="contact-message" placeholder="Su mensaje" required=""></textarea>
                        </div>
                        <button type="submit" className="btn btn-default btn-filled animated-btn">Enviar mensaje</button>
                     </form>
                  </div>
                  <WhatsAppIcon />
               </div>
               <GoogleMap />
            </div>
         </section>
      );
   }
}

export default Contact;
